<template>
  <div>
    <div v-if="$store.state.show">
      <h2>Android端</h2>
      <h3>1.API更新</h3>
      <ul>
        <li>
          （1）删除旧版的滤镜Api：<br />
          setFilterEnum( TiFilterEnum filterEnum,int Progress )<br />
        </li>
        <li>
          （2）更换滤镜Api现版本统一为：<br />
          setBeautyFilter(String filterName,int progress)<br />
        </li>
        <li>
          （3）参数集可参考UI模块中的<br />
          cn.tillusory.tiui.model.TiBeautyFilter
        </li>
        <li>
          （4）新的初始化方法：<br />
          <pre v-highlightA>
          <code>
            java
            TiSDK.initSDK("key",
            this, new TiSDK.TiInitCallback() {
                @Override public void success() {

                }

                @Override public void failure() {

                }
            });
          </code>
        </pre>
          旧版初始化方法已经移除
        </li>
        <li>
          （5）为了解耦和功能明确，
          <span>TiSDKManager</span>不再提供参数的获取，并将相关方法移除。
          <pre v-highlightA>
          <code>
            以下类已被移除：
            cn.tillusory.sdk.bean.TiFlterEnum

            以下类被迁移至UI模块的Model下：
            cn.tillusory.sdk.bean.TiGestureConfig
            cn.tillusory.sdk.bean.TiGiftConfig
            cn.tillusory.sdk.bean.TiGreenScreenConfig
            cn.tillusory.sdk.bean.TiInteractionConfig
            cn.tillusory.sdk.bean.TiMakeupConfig
            cn.tillusory.sdk.bean.TiMaskConfig
            cn.tillusory.sdk.bean.TiPortraitConfig
            cn.tillusory.sdk.bean.TiStickerConfig
            cn.tillusory.sdk.bean.TiTypeEnum
            cn.tillusory.sdk.bean.TiWatermarkConfig
          </code>
          </pre>
        </li>
      </ul>
      <h3>2.如何更新</h3>
      <ul>
        <li>
          （1）直接使用我们的UI的用户<br />
          直接替换Module
          即可,替换后可能因为缓存的影响导致编辑器不能正常联想和提示，请 <span
            >rebuild</span
          >
          和进行同步。
        </li>
        <li>
          （2）有UI改动的用户<br />
          由于 <span>SDK</span>
          不再提供参数的获取，所以参数将有UI部分进行管理，推荐替换Module后将原先的改动合并。
        </li>
        <li>
          （3）不使用我们的UI或UI改动较大的用户<br />
          <ul class="ul_style">
            <li>
              贴纸配置参数集合，动态标签参数集等数据集在 <span>assets</span>
              下的对应目录下的 <span>json</span> 中，需要自行解析获取， <span
                >TiSDKManager</span
              >
              不再提供参数集的获取。
            </li>
            <li>获取预览图和获取资源文件下载地址需要自行拼接。</li>
            <li>
              资源文件的是否下载，UI的状态保存，参数的持久化需要自行实现。
            </li>
            <li>具体的实现可以参考开源的UI模块。</li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-else>
      <h2>Android</h2>
      <h3>1.API update</h3>
      <ul>
        <li>
          （1）Delete old filter API：<br />
         setFilterEnum( TiFilterEnum filterEnum,int Progress )<br />
        </li>
        <li>
          （2）Replace the filter. The current version of API is unified as：<br />
          setBeautyFilter(String filterName,int progress)<br />
        </li>
        <li>
          （3）The parameter set can refer to the in the UI module<br />
          cn.tillusory.tiui.model.TiBeautyFilter
        </li>
        <li>
          （4）New initialization method：<br />
          <pre v-highlightA>
          <code>
            java
            TiSDK.initSDK("key",
            this, new TiSDK.TiInitCallback() {
                @Override public void success() {

                }

                @Override public void failure() {

                }
            });
          </code>
        </pre>
          The old initialization method has been removed
        </li>
        <li>
          （5）For decoupling and functional clarity, <span>TiSDKManager</span> no longer
          provides parameter acquisition and removes relevant methods.
          <pre v-highlightA>
          <code>
            The following classes have been removed：
            cn.tillusory.sdk.bean.TiFlterEnum

            The following classes have been removed and migrated to the Model of the UI module：
            cn.tillusory.sdk.bean.TiGestureConfig
            cn.tillusory.sdk.bean.TiGiftConfig
            cn.tillusory.sdk.bean.TiGreenScreenConfig
            cn.tillusory.sdk.bean.TiInteractionConfig
            cn.tillusory.sdk.bean.TiMakeupConfig
            cn.tillusory.sdk.bean.TiMaskConfig
            cn.tillusory.sdk.bean.TiPortraitConfig
            cn.tillusory.sdk.bean.TiStickerConfig
            cn.tillusory.sdk.bean.TiTypeEnum
            cn.tillusory.sdk.bean.TiWatermarkConfig
          </code>
          </pre>
        </li>
      </ul>
      <h3>2.How to update</h3>
      <ul>
        <li>
          （1）Users who use our UI directly<br />
          Just replace the Module directly. After replacement, the editor may
          not associate and prompt normally due to the impact of cache. Please
          rebuild and synchronize.
        </li>
        <li>
          （2）Users with UI changes<br />
          Since the <span>SDK</span> no longer provides the acquisition of parameters, the
          parameters will be managed by the UI part. It is recommended to
          replace the Module and combine the original changes
        </li>
        <li>
          （3）Users who don't use our UI or whose UI has changed a lot<br />
          <ul class="ul_style">
            <li>
              Data sets such as sticker configuration parameter set and dynamic
              tag parameter set are in <span>JSON</span> in the corresponding directory under
              <span>Assets</span>, which need to be obtained by parsing. <span>TiSDKManager</span> no
              longer provides the acquisition of parameter sets
            </li>
            <li>
              To obtain the preview image and obtain the download address of the
              resource file, you need to add them together by yourself
            </li>
            <li>
              Whether the resource file is downloaded, the STATE of the UI is
              saved, and the parameter persistence needs to be implemented by
              itself
            </li>
            <li>
              The specific implementation can refer to the open source UI module
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 18px;
  font-weight: 400;
  color: #3c4858;
  margin: 20px 0;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
span {
  color: #e91e63;
}
img {
  width: 431px;
  margin: 20px 60px;
}
li {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #3c4858;
}
.ul_style {
  padding-left: 10px;
  li {
    line-height: 32px;
    list-style: disc inside none;
  }
  a {
    color: #4c8cf5;
    text-decoration: underline;
  }
}
</style>